<template>
  <div class="contact-us">
    <!-- <div class="block-banner text-center">
      <h1>CONTACT US</h1>
    </div> -->
    <!-- <div class="content-wrapper contact-group">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-6">
          <div class="input-form">
            <div class="input-header mb-4">
              <h4>Get in touch</h4>
              <p>
                Please enter the details of your requesst. A member of our
                support staff will respond as soon as possible.
              </p>
            </div>
            <div class="d-flex align-items-center gap-2">
              <v-text-field
                clearable
                label="YOUR NAME"
                variant="outlined"
              ></v-text-field>
              <v-text-field
                clearable
                label="EMAIL"
                variant="outlined"
              ></v-text-field>
            </div>
            <v-text-field
              clearable
              label="PHONE NUMBER"
              variant="outlined"
            ></v-text-field>
            <v-textarea
              clearable
              label="YOUR MESSAGE"
              variant="outlined"
            ></v-textarea>
            <div class="button-group">
              <button class="btn primary-btn sumbit-btn text-center">
                Sumbit Now
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-6">
          <div class="contact-form">
            <div class="contact-form-header mb-5">
              <h4>Contact Us</h4>
              <p>You can contact us or visit us at following information.</p>
            </div>
            <div class="address d-flex gap-1 mb-5">
              <h4>Address:</h4>
              <p>
                No.508 A, Lower Kyee Myin daing Road, (Home Lan Bus Stop), Kyee
                Myin Daing Township. Yangon.
              </p>
            </div>
            <div class="address d-flex gap-1 mb-5">
              <h4>Email:</h4>
              <p>info@globetradingmm.com</p>
            </div>
            <div class="address d-flex gap-1 mb-5">
              <h4>Call Us:</h4>
              <p>+959 880 441 046</p>
            </div>
            <div class="opening-time gap-1 d-flex mb-5">
              <h4>Opening time:</h4>
              <p>
                Our store has re-opened for shopping, exchanges every day 11am
                to 7pm
              </p>
            </div>

            <hr class="p-0 m-0" />
            <div class="social-link-group mt-5">
              <div class="social-header mb-5">
                <h4>Social Link</h4>
                <p>Follow us on following socail media for update news</p>
              </div>
              <div class="d-flex align-items-center gap-3">
                <a
                  href="https://www.facebook.com/globe.trading"
                  class="icon-link"
                  target="_blank"
                  ><i class="fa-brands fa-facebook-f"></i
                ></a>
                <a href="/" class="icon-link" target="_blank"
                  ><i class="fa-brands fa-youtube"></i
                ></a>

                <a
                  href="https://www.tiktok.com/@globe_trading1?is_from_webapp=1&sender_device=pc"
                  class="icon-link"
                  target="_blank"
                  ><i class="fa-brands fa-tiktok"></i
                ></a>
                <a
                  href="https://www.instagram.com/globetrading4?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  class="icon-link"
                  target="_blank"
                  ><i class="fa-brands fa-instagram"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3819.6016885130807!2d96.12378541094385!3d16.796481019491726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1eba7e5c576c1%3A0x3feadeb298c98ae3!2sXP-Pen%20Myanmar!5e0!3m2!1sen!2sth!4v1731084500184!5m2!1sen!2sth"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div> -->

    <div class="content-wrapper contact-group">
      <div class="contact-header text-center">
        <h2>Get in touch</h2>
        <p>Ready to help you.Let's Chat about how can we help</p>
      </div>

      <div class="row card-section">
        <div class="col-12 col-md-6 col-xl-3">
          <div class="contact-card">
            <div class="icon">
              <i class="fa-regular fa-comment"></i>
            </div>
            <div class="card-text">
              <h5>Chat to sales</h5>
              <p>Speak to our friendly sales</p>
            </div>
            <div class="card-button">
              <a
                href="https://m.me/globe.trading"
                target="_blank"
                class="card-btn btn"
                >Chat to sales</a
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <div class="contact-card">
            <div class="icon">
              <i class="fa-regular fa-comments"></i>
            </div>
            <div class="card-text">
              <h5>Chat to support</h5>
              <p>We're here for help</p>
            </div>
            <div class="card-button">
              <a
                href="https://m.me/globe.trading"
                target="_blank"
                class="card-btn btn"
                >Chat to support</a
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <div class="contact-card">
            <div class="icon">
              <i class="fa-solid fa-location-dot"></i>
            </div>
            <div class="card-text">
              <h5>Visit us</h5>
              <p>Visit our store</p>
            </div>
            <div class="card-button">
              <a
                href="https://maps.app.goo.gl/WBdViVkU6wvevsot7"
                target="_blank"
                class="card-btn btn"
                >Get directions</a
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <div class="contact-card">
            <div class="icon">
              <i class="fa-solid fa-phone"></i>
            </div>
            <div class="card-text">
              <h5>Call us</h5>
              <p>Everyday from 9:00 AM to 6:00 PM</p>
            </div>
            <div class="card-button">
              <a @click="callUs()" class="card-btn btn">Call our store</a>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-xl-6">
          <div class="bottom-card">
            <div class="contact-header text-center second-header">
              <h2>Contact Us</h2>
              <p>You can also contact us with the following information</p>
            </div>

            <div class="contact-information">
              <div class="address d-flex">
                <h6>
                  <span class="material-symbols-outlined"> location_on </span>
                </h6>
                <span class="dash">-</span>
                <p>
                  No.508 A, Lower Kyee Myin daing Road, (Home Lan Bus Stop),
                  Kyee Myin Daing Township. Yangon.
                </p>
              </div>
              <div class="contact d-flex">
                <h6><span class="material-symbols-outlined"> call </span></h6>
                <span class="dash">-</span>
                <p>+959 880 441 046</p>
              </div>
              <div class="contact d-flex">
                <h6><span class="material-symbols-outlined"> mail </span></h6>
                <span class="dash">-</span>
                <p>info@globetradingmm.com</p>
              </div>
              <div class="contact d-flex">
                <h6>
                  <span class="material-symbols-outlined"> schedule </span>
                </h6>
                <span class="dash">-</span>
                <p>Everyday from 9:00 AM to 6:00 PM</p>
              </div>
              <div class="social-group">
                <div class="social-header">
                  <h6>Social Link</h6>
                </div>

                <div class="d-flex align-items-center gap-3">
                  <a href="/" class="social-icon">
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="/" class="social-icon">
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                  <a href="/" class="social-icon">
                    <i class="fa-brands fa-tiktok"></i>
                  </a>
                  <a href="/" class="social-icon">
                    <i class="fa-brands fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-6">
          <div class="bottom-card">
            <div class="contact-header text-center second-header">
              <h2>Message Us</h2>
              <p>We'll get you back to you within 24 hours.</p>
            </div>

            <div class="message-box">
              <div class="d-flex align-items-center gap-2 md-flex-wrap">
                <div class="name-group">
                  <h6>Name</h6>
                  <input type="text" placeholder="Your Name" />
                </div>

                <div class="name-group">
                  <h6>Email</h6>
                  <input type="email" placeholder="Your Email" />
                </div>
              </div>

              <div class="name-group">
                <h6>Phone Number</h6>
                <input type="number" placeholder="Phone Number" />
              </div>

              <div class="name-group">
                <h6>Message</h6>
                <textarea name="" id="" placeholder="Message"></textarea>
              </div>

              <div class="button">
                <button class="btn message-btn text-center">
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  setup() {
    const callUs = () => {
      Swal.fire({
        title: "+959 880 441 046",
        text: "Here our number call us",
        icon: "info",
      });
    };

    return {
      callUs,
    };
  },
};
</script>

<style scoped>
/* --- new design ----*/

.contact-header {
  margin-bottom: 60px;
}

.second-header {
  margin-bottom: 20px;
}

.contact-header h2 {
  font-weight: bold;
  font-family: "Gilory-Bold", sans-serif;
}

.contact-header p {
  color: #8f8f8f;
}

.card-section {
  margin-bottom: 100px;
}

.contact-card {
  padding: 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.bottom-card {
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  padding: 20px;
}

.icon {
  width: 45px !important;
  height: 45px !important;
  background: #b5d5f2;
  width: max-content;
  border-radius: 10px;
  color: #0a65af;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .icon .fa-regular {
  font-size: 20px;
}

.icon .fa-solid {
  font-size: 20px;
  padding: 0px 2px;
} */

.contact-card .icon {
  margin-bottom: 30px;
}

.card-text {
  margin-bottom: 20px;
}

.card-text h5 {
  font-weight: bold;
  margin-bottom: 5px !important;

  font-family: "Gilory-Bold", sans-serif;
}

.card-text p {
  font-size: 14px !important;
  color: #8f8f8f;
}

.card-btn {
  padding: 10px 15px;
  border: 0.5px solid #8f8f8f;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
}

.icon:hover {
  background: var(--hover-color);
  color: #fff;
}

.card-btn:hover {
  background: var(--hover-color);
  color: #fff !important;
  border: none;
}

.message-box {
  width: 100%;
  margin: 0px auto;
}

.box-group {
  width: 100%;
}

.name-group {
  width: 100%;
  margin-bottom: 10px;
}

.name-group input {
  width: 100%;
  padding: 10px;
  height: 40px;
  border: 0.5px solid #8f8f8f;
  border-radius: 6px;
}

.name-group textarea {
  width: 100%;
  border: 0.5px solid #8f8f8f;
  border-radius: 6px;
  padding: 10px;
}

.email {
  width: 100%;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.message-btn {
  width: 100%;
  background: var(--background-color);
  padding: 5px 15px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  transition: 0.3 ease-in-out;
}

.message-btn:hover {
  background: #0a65af;
}

.address,
.contact {
  margin-bottom: 20px;
}

.address .material-symbols-outlined {
  white-space: nowrap;
  font-weight: bold;
  font-size: 24px;
  color: var(--hover-color);
}

.address p {
  font-size: 16px !important;
  font-weight: bold;
  margin-left: 20px !important;
}

.contact .material-symbols-outlined {
  white-space: nowrap;
  font-weight: bold;
  font-size: 24px;
  color: var(--hover-color);
}

.contact p {
  font-size: 16px !important;
  font-weight: bold;
  margin-left: 20px !important;
}

.dash {
  margin-left: 20px;
}

.social-header {
  margin-bottom: 20px;
}

.social-header h6 {
  font-size: 20px !important;
  font-weight: bold;
  font-family: "Gilory-Bold", sans-serif;
}

.social-icon {
  width: 40px;
  height: 40px;
  background: var(--background-color);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  color: #fff !important;
}

.social-icon:hover {
  background: #0a65af;
}

@media (max-width: 765px) {
  .bottom-card {
    border: 0.5px solid #8f8f8f;
  }

  .col-12 {
    margin-bottom: 40px;
  }
}

@media (max-width: 692px) {
  .name-group {
    max-width: 100%;
    width: 100%;
  }
  .gap-5 {
    gap: 0px !important;
  }
}

/* .block-banner {
  width: 100%;
  height: 400px;
  background-image: url("@/assets/images/contact_us.png");
  align-content: center;
  z-index: 1;
}

.block-banner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.block-banner h1 {
  color: #ffffff;
  font-family: "Gilory-Bold", sans-serif;
  font-weight: bold;
}

.contact-group {
  margin-top: -60px;
  z-index: 999;
}

.input-form,
.contact-form {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #ffffff;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px,
    rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 2px 2px -1px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px,
    rgba(42, 51, 70, 0.03) 0px 10px 10px -5px,
    rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
  border-radius: 10px;
}

.input-header h4 {
  font-family: "Gilory-Bold", sans-serif;
  font-weight: bold;
}

.input-header p {
  font-size: 14px !important;
  opacity: 0.8;
}

.sumbit-btn {
  display: inline-block;
  text-transform: uppercase;
}

.contact-form-header h4 {
  font-family: "Gilory-Bold", sans-serif;
  font-weight: bold;
}

.contact-form-header p {
  font-size: 14px !important;
  opacity: 0.8;
}

.address {
  cursor: pointer;
}

.address h4 {
  width: 90px;
  margin-bottom: 0px !important;
  font-size: 20px;
  font-family: "Gilory-Bold", sans-serif;
  font-weight: bold;
}

.opening-time h4 {
  width: 90px;
  font-size: 20px;
  font-family: "Gilory-Bold", sans-serif;
  font-weight: bold;
}

.opening-time p {
  font-family: "Gilory-Bold", sans-serif;
  font-weight: bold;
}

.address p {
  font-family: "Gilory-Bold", sans-serif;
  font-weight: bold;
}

.social-header h4 {
  font-family: "Gilory-Bold", sans-serif;
  font-weight: bold;
}

.social-header p {
  font-size: 14px !important;
  opacity: 0.8;
}

.icon-link {
  padding: 15px;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px,
    rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 2px 2px -1px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px,
    rgba(42, 51, 70, 0.03) 0px 10px 10px -5px,
    rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
  border-radius: 10px;
  background: var(--background-color);
}

.icon-link .fa-brands {
  color: var(--font-color-white);
}

.icon-link:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.map {
  margin-top: 50px;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.map iframe {
  width: 1400px;
  height: 500px;
  border-radius: 10px;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px,
    rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 2px 2px -1px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px,
    rgba(42, 51, 70, 0.03) 0px 10px 10px -5px,
    rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
}

@media (max-width: 680px) {
  .map iframe {
    width: 680px;
  }
}

@media (max-width: 420px) {
  .map iframe {
    width: 420px;
    height: 300px;
  }
} */
</style>
