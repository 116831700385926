<template>
  <div class="product-detail-wrapper">
    <DetailProduct></DetailProduct>
  </div>
</template>

<script>
import DetailProduct from "../../components/DetailProduct";
export default {
  components: { DetailProduct },
};
</script>

<style>
.product-detail-wrapper {
  margin-top: 180px;
}
</style>
