<template>
  <div class="checkout-wrapper">
    <Checkout></Checkout>
  </div>
</template>

<script>
import CheckoutNav from "../components/checkout/CheckoutNav";
import Checkout from "../components/checkout/Checkout";
export default {
  components: {
    CheckoutNav,
    Checkout,
  },
};
</script>

<style></style>
